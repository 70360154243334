import styled from 'styled-components';

export const CloseButton = styled.button`
  background-color: var(--red-500);
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  padding: 0.6rem 1rem;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ModalLinkContainer = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1000;

  height: 100vh;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  .link__modal {
    border-radius: 5px;
    background-color: #121212;
    border: 1px solid gray;
    padding: 2rem;
    width: 100%;
    max-width: calc(100vw - 400px);
    height: 100%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;

    .modal__header {
      display: flex;
      justify-content: space-between;

      button {
        background-color: var(--red-500);
        border: none;
        border-radius: 5px;

        height: 40px;
        width: 40px;
      }
    }

    }
`;


export const SearchForm = styled.form`
  flex: 1;
  display: flex;
  gap: 10px;
  margin: 10px 0 20px;

  input {
    flex: 1;

    background-color: transparent;
    border: 1px solid gray;
    padding: 10px;
    border-radius: 5px;
    
    color: white;
    outline: none;
  }

  button {
    background-color: var(--purple-500);
    border: none;
    color: white;
    border-radius: 5px;
    padding: 0 20px;
  }
`
