import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 6rem);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  background-color: var(--gray-700);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-900);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-700);
  }

  .header {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: baseline;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    
    button {
      padding: 1rem;
      border-radius: 10px;
      background-color: var(--gray-500);
      color: white;
      font-weight: 700;
      border: 1px solid var(--purple-500);
      transform: translateY(1px);
      outline: none;
    }
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .container__title{
    font-size: 1.5rem;
  }

  .platformSelector__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;

  }

`

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  overflow: auto;
`;

export const ModalContent = styled.div`
  background-color: var(--gray-900);
  color: white;
  padding: 2rem;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  max-width: 500px;
  width: 100%;
  margin: 2rem auto;

  p {
    margin-bottom: 0.5rem;
  }
  
  h2, h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      svg {
        margin-left: 8px;
      }
    }
  }

  .divHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  .divCloseButton {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .divTitle {
    width: 90%;
  }

  .divActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
  }
`;

export const CloseButton = styled.button`
  background-color: var(--red-500);
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  padding: 0.6rem 1rem;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ConfirmButton = styled.button`
  background-color: green;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  padding: 0.6rem 1.2rem;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
`;